export default {
  	// auth: {
  	// 	strategies: {
   //  		'symfonyJWT': {
			// 	provider: 'symfony/jwt',
			// 	url: 'http://immobiliere-essaouira-cms-dev.graines-digitales.fr/authentication_token',
			// 	endpoints: {
				
			// 	},
			// 	token: {
			// 		property: 'access_token',
			// 		maxAge: 60 * 60
			// 	},
			// 	refreshToken: {
			// 		maxAge: 20160 * 60
			// 	},
		 //    }
  	// 	}
  	// }
}

<template>
    <div class="page-wraper">
        <ButtonWhatsApp/>
      <PageHeader/>
      <div class="page-content">
          <div class="page-headband">
            <!-- <TheSlider/> -->
          </div>
          <div class="page-content-wraper">
              <nuxt/>
          </div>
      </div>
      <PageFooter/>
      <TheScrollTop/>
      
    </div>
</template>

<script>
import ButtonWhatsApp from '~/components/ButtonWhatsApp'
import PageHeader from '~/components/TheHeader'
import TheSlider from '~/components/TheSlider'
import PageFooter from '~/components/TheFooter'
import TheScrollTop from '~/components/TheScrollTop'
export default {
    name: 'default',
    components : {
        PageHeader,
        TheSlider,
        PageFooter,
        TheScrollTop,
        ButtonWhatsApp
    }
}
</script>


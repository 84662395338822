export const state = () => ({
  token: null,
  maintenance: false
})

export const mutations = {
  setToken(state, data) {
    state.token = data
  },
  setMaintenance(state, data) {
    state.maintenance = data
  }
}

export const actions = {
  async getToken({commit , context}) {

      console.log('promise auth/getToken')
      const data = {
          "username": "johan.remy",
          "password": "iep@ssword"
      }
      await this.$axios.post('/login_check', data )
        .then((response) => {
          commit('setToken', response.data.token)
        this.$axios.setToken(response.data.token, 'Bearer')
      }).catch((error) => { 
       console.log(error) 
      })
  }
}

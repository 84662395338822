var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-headband"},[_c('div',{staticClass:"wt-bnr-inr bg-parallax bg-bottom-center lz-loading _ratio-container _unknown-ratio-container"},[(_vm.data.primaryImage)?_c('img',{staticClass:"img-responsive img-responsive-horizontal full zoom _lazyload",attrs:{"width":_vm.$getImageSizeByFilterSets(
          'width',
          _vm.getFormat(_vm.data.primaryImage, _vm.data.secondaryImage)
        ),"height":_vm.$getImageSizeByFilterSets(
          'height',
          _vm.getFormat(_vm.data.primaryImage, _vm.data.secondaryImage, true)
        ),"src":_vm.imagePath(_vm.data.primaryImage, _vm.data.secondaryImage, false),"alt":_vm.imageAlt(_vm.data.primaryImage)},on:{"load":_vm.onImgLoad}}):_vm._e(),_vm._v(" "),_c('img',{staticClass:"img-responsive img-responsive-vertical full zoom _lazyload",attrs:{"width":_vm.$getImageSizeByFilterSets(
          'width',
          _vm.getFormat(_vm.data.primaryImage, _vm.data.secondaryImage, false)
        ),"height":_vm.$getImageSizeByFilterSets(
          'height',
          _vm.getFormat(_vm.data.primaryImage, _vm.data.secondaryImage, false)
        ),"src":_vm.imagePath(_vm.data.primaryImage, _vm.data.secondaryImage, true),"alt":_vm.imageAlt(_vm.data.primaryImage)},on:{"load":_vm.onImgLoad}}),_vm._v(" "),_c('div',{staticClass:"overlay-main bg-black opacity-04"}),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"wt-bnr-inr-entry"},[_c('div',{staticClass:"banner-title-outer"},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.show)?_c('div',{staticClass:"banner-title-name"},[_c('client-only',[(_vm.checkDevice())?_c('h1',{staticClass:"text-white text-uppercase letter-spacing-5 font-18 font-weight-300"},[_c('span',{staticClass:"item-headband"},[_vm._v(_vm._s(_vm.$t(_vm.data.alternativePushForward.nature)))]),_vm._v(" "),_c('span',{staticClass:"item-headband"},[_vm._v(_vm._s(_vm.$t(_vm.data.alternativePushForward.type)))]),_vm._v(" "),(_vm.data.alternativePushForward.floorSize > 0)?_c('span',{staticClass:"item-headband"},[_vm._v(_vm._s(_vm.$t(_vm.data.alternativePushForward.floorSize))+" m²")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"item-headband"},[_vm._v(_vm._s(_vm.$t(_vm.data.alternativePushForward.place)))]),_vm._v(" "),(_vm.data.alternativePushForward.price > 0)?_c('span',{staticClass:"item-headband"},[_vm._v(_vm._s(_vm.$t(_vm.data.alternativePushForward.price))+" €")]):_vm._e()]):_c('h1',{staticClass:"text-white text-uppercase letter-spacing-5 font-18 font-weight-300"},[_vm._v("\n                  "+_vm._s(_vm.$t(_vm.data.pushForward).replace("- 0 m²", ""))+"\n                ")])])],1):_vm._e()])],1),_vm._v(" "),_c('div',{staticClass:"p-tb20"},[_c('div',[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.show)?_c('ul',{staticClass:"wt-breadcrumb breadcrumb-style-2"},_vm._l((_vm.data.breadcrumb),function(value,index){return _c('li',{key:index},[(value.route)?_c('nuxt-link',{attrs:{"to":_vm.getLocalizedRoute({
                        name: value.route.name,
                        params: value.route.params,
                      })}},[_vm._v("\n                    "+_vm._s(value.name)+"\n                  ")]):_c('span',[_vm._v(_vm._s(value.name))])],1)}),0):_vm._e()])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }